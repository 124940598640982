<template>
    <div>
        <Slider />
        <div class="page-content bg-white">
            <!-- Mission and vision -->
            <div class="section-full bg-white content-inner">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-12">
                            <div class="sort-title clearfix text-center">
                                <h4>{{this.$store.getters.getSchoolName}}</h4>
                                <p style="margin-top:10px; ">
                                    Welcome to Citadel International School (CIS), a place where we know your children
                                    will be part of an engaging and exciting learning journey.
                                    We strive to challenge and inspire all of our learners to develop their skills and
                                    nurture values through a love of learning. We want our students to responsibly
                                    embrace the challenges of tomorrow.
                                    At CIS we very much believe that there are three very important stakeholders in your
                                    child's education: your child; you, the parents; and the school and its teachers.
                                    Your support and opinions are important and contribute to your child's future
                                    success, whilst supporting our mission “Everyone counts, Everyone contributes,
                                    Everyone succeeds.”
                                    We thank you for choosing Citadel International School to support your child's
                                    future aspirations. We are committed to making a difference, and we very much look
                                    forward to working in partnership with you on this journey
                                </p>
                            </div>
                            <!-- icon box style 1 aligh center -->
                            <!-- <div class="section-content box-sort-in button-example p-b0">
                                <div class="row">
                                    <div class="col-lg-6 col-md-6 col-sm-12 m-b30">
                                        <div class="icon-bx-wraper bx-style-2 m-l40 p-a30 left bg-gray icon-bx-ho">
                                            <div class="icon-bx-sm bg-primary m-b20"><span class="icon-cell">
                                                    <i class="fa fa-shield" style="font-size: 30px;"></i></span> </div>
                                            <div class="icon-content p-l40">
                                                <h5 class="dlab-tilte">Mission</h5>
                                                <p>
                                                    Citadel British International school (CIS) will provide a comprehensive and 
                                                    challenging education that fosters informed and engaged local, regional and global citizenship. 
                                                    We inspire students to be lifelong learners who contribute positively to a diverse and changing world.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-6 col-md-6 col-sm-12 m-b30">
                                        <div class="icon-bx-wraper bx-style-2 m-l40 p-a30 left bg-gray icon-bx-ho">
                                            <div class="icon-bx-sm bg-primary m-b20"><a href="javascript:void(0);"
                                                    class="icon-cell">
                                                    <i class="fa fa-shield" style="font-size: 30px;"></i></a> </div>
                                            <div class="icon-content p-l40">
                                                <h5 class="dlab-tilte">Vision</h5>
                                                <p>
                                                    Preparing a creative, cooperative generation that is ethically and
                                                    scientifically committed, keeping pace with development and interacting with 
                                                    its society in light of National standards for education.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div> -->
                        </div>
                    </div>
                </div>
            </div>

            <!-- Values -->
            <!-- <div class="section-full bg-white content-inner overlay-black-dark bg-img-fix text-white values" style="">
                <div class="container">
                    <div class="row">
                        <div class="col-md-12">
                            <div class="sort-title clearfix text-center text-white">
                                <h4>Our Values</h4>
                                <p style="margin: 10px 0 0 0;">At CIS, we promote values that combine the pillars of character education with our cultural and spiritual essence. We encourage and recognize the following values:</p>
                            </div>
                            <div class="section-content box-sort-in m-b10 p-b0 button-example m-b30 ">
                                <div class="row">
                                    <div class="col-md-4">
                                        <ul class="list-star red list-box">
                                            <li>
                                                Innovation <br>
                                                <small> Citadel School Students are encouraged to research, explore, and use all the tools to uncover something new. </small>
                                            </li>
                                            <li>
                                                Honesty <br>
                                                <small>Citadel School Students have strong moral principles that guide their actions and relations.</small>
                                            </li>
                                        </ul>
                                    </div>
                                    <div class="col-md-4">
                                        <ul class="list-star red list-box">
                                            <li>
                                                Critical thinking <br>
                                                <small>Citadel School Students use a higher level of thinking to solve complex problems.</small>
                                            </li>
                                            <li>
                                                Citizenship <br>
                                                <small>Citadel School Students are supportive to others, serve the community and are responsible world citizens.</small>
                                            </li>
                                        </ul>
                                    </div>
                                    <div class="col-md-4">
                                        <ul class="list-star red list-box">
                                            <li>
                                                Respect <br>
                                                <small>Citadel School Students are polite and respectful to everyone on campus and follow the directions of all school officials.</small>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div> -->


            <!-- Services -->
            <!-- <div class="section-full bg-white content-inner">
                <div class="container">
                    <div class="row service-area-one">
                        <div class="col-lg-4 m-b30 hidden-sm">
                            <div class="rdx-thu"><img src="@/assets/imgs/logo.png" alt=""></div>
                        </div>
                        <div class="col-lg-8">
                            <div class="section-head">
                                <h2 class="title">School's Services</h2>
                            </div>
                            <div class="row">
                                <div class="col-md-12">
                                    <ul class="list-check primary text-black circle">
                                        <li>Most big-name instructors: IGCSE</li>
                                        <li>Following up with parents & Students: SMS & LMS</li>
                                        <li>Facilities for registration: Online & In Campus</li>
                                        <li>Regular Quizzes & Exams: School Calendar</li>
                                        <li>Transportation</li>
                                        <li>Sport: PE – Football, Basketball, Swimming pool, padel</li>
                                        <li>Entertainment: Music / Art / Drama Club</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div> -->

        </div>
    </div>
</template>

<script>
    import Slider from '@/components/Slider.vue'
    export default {
        components: {
            Slider
        }
    }
</script>

<style>
    .values {
        background-image: url("~@/assets/imgs/values.jpg");
    }
</style>